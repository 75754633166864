$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1550px;

/* Max width 767px */
@media only #{$media} and ($feature_max : $value_two) {

    .btn {
        font-size: 13px;
        padding: 13px 33px;
    }
    .ptb-80 {
        padding: {
            top: 50px;
            bottom: 50px;
        }
    }
    .section-title {
        margin-bottom: 40px;

        h2 {
            font-size: 20px;
        }
    }

    .main-banner {
        height: 100%;
        padding: {
            top: 150px;
            bottom: 80px;
        }
        background-position: center;

        .hero-content {
            margin-bottom: 45px;
            text-align: center;

            h1 {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 15px;
            }
        }
        .banner-image {
            text-align: center;

            img {
                display: none;
                position: relative;

                &:last-child {
                    display: block;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
        .banner-form {
            padding: 30px 20px;
            margin: 0 !important;
        }
    }
    .hosting-main-banner {
        height: 100%;
        padding: {
            top: 170px;
            bottom: 75px;
        }
        .hosting-banner-content {            
            h1 {
                font-size: 25px;
            }
            ul {
                li {
                    font-size: 13px;
                }
            }
            p {
                font-size: 16px;
                margin: {
                    bottom: 25px;
                    top: 20px;
                }
            }
        }
        .hosting-banner-image {
            text-align: center;
            margin-top: 50px;

            img {
                display: none;

                &:nth-child(10) {
                    display: block;
                    position: relative;
                }
            }
        }
    }
    .shape1, .shape2, .shape3, .shape4, .shape5, .shape6, .shape7, .shape8, .shape9, .shape10 {
        display: none;
    }

    .boxes-area {
        margin-top: 0;
        padding-bottom: 20px;
    }
    .single-box {
        margin-bottom: 30px;

        h3 {
            font-size: 17px;
        }
    }

    .domain-search-content {
        h2 {
            font-size: 20px;
        }
        form {
            border-radius: 0;

            .form-control {
                width: 75%;
            }
            .domain-select {
                width: 25%;
                padding-right: 0;
            }
            button {
                float: unset;
                width: unset;
                height: unset;
                padding: 13px 40px;
            }
        }
        ul {
            display: block;
            margin-top: 15px;

            li {
                display: inline-block;
                flex: unset;
                padding: 0 15px;
                margin-top: 15px;
            }
        }
    }

    .why-choose-us-image {
        margin-bottom: 15px;

        img {
            display: none;

            &:last-child {
                display: inline-block;
            }
        }
    }
    .single-why-choose-us {
        margin-top: 30px !important;

        .icon {
            i {
                &::before {
                    font-size: 35px;
                }
            }
        }
        h3 {
            font-size: 17px;
        }
    }

    .services-content {
        .box {
            margin-top: 20px;
        }
    }
    .services-right-image {
        text-align: center;
        margin-top: 45px;
        
        img {
            display: none;
            position: relative;

            &:last-child {
                display: inline-block;
            }
        }
        &.single-right-image {
            img {
                &.bg-image  {
                    display: none;
                }
            }
        }
    }
    .services-left-image {
        text-align: center;
        margin-bottom: 45px;

        img {
            display: none;
            position: relative;

            &:last-child {
                display: inline-block;
            }
        }
        &.single-left-image {
            img {
                &.bg-image {
                    display: none;
                }
            }
        }
    }
    .single-services-box {
        h3 {
            font-size: 17px;
        }
    }
    .single-features {
        padding-left: 20px;

        .icon {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 25px;
        }
        h3 {
            font-size: 17px;
        }
    }
    .single-hosting-features {
        h3 {
            font-size: 17px;
        }
    }

    .cta-content {
        text-align: center;
        margin-bottom: 25px;

        h3 {
            font-size: 20px;
        }
    }
    .cta-right-content {
        text-align: center;

        .buy-btn {
            display: block;
            top: 0;
            padding-left: 0;
            margin-top: 20px;
        }
    }
    .single-team {
        padding: {
            top: 30px;
            bottom: 30px;
        }

        .team-image {
            padding: {
                left: 20px;
                right: 20px;
            }
        }
        .team-content {
            padding: {
                left: 20px;
                right: 20px;
            }

            .team-info {
                margin: {
                    right: 10px;
                    left: 10px;
                }

                h3 {
                    font-size: 17px;
                }
            }
        }
    }

    .funfact {
        margin-bottom: 30px;

        h3 {
            font-size: 25px;
            margin-bottom: 3px;
        }
    }

    .contact-cta-box {
        text-align: center;
        margin: 20px 0 0;
        max-width: 100%;
        padding: 30px 20px;

        h3 {
            font-size: 17px;
        }
        .btn {
            position: relative;
            right: 0;
            top: 0;
            margin-top: 15px;
        }
    }
    .single-works {
        .icon {
            right: 10px;;
            width: 38px;
            height: 38px;
            line-height: 36px;

            svg {
                width: 15px;
            }
        }
        .works-content {
            padding: 15px;

            h3 {
                font-size: 17px;
            }
        }
        &:hover {
            .icon {
                top: 10px;
            }
        }
    }

    .pricing-area {
        padding-bottom: 20px;
    }
    .pricing-table {
        padding-bottom: 30px;

        .pricing-header {
            h3 {
                font-size: 17px;
            }
        }
        .price {
            margin-bottom: 25px;

            span {
                font-size: 27px;
            }
        }
    }
    .single-pricing-table {
        padding: 25px 20px;
        margin-bottom: 30px;

        .pricing-header {
            h3 {
                font-size: 17px;
            }
            i {
                &::before {
                    font-size: 40px;
                }
            }
        }
        .price {
            span {
                font-size: 27px;
            }
        }
    }

    .feedback-slides {
        .client-feedback {
            .single-feedback {
                text-align: center;
                margin-bottom: 30px;
                padding: {
                    left: 20px;
                    right: 20px;
                    top: 30px;
                    bottom: 30px;
                }
                .client-img {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 17px;
                }
                p {
                    font-size: 14px;
                }
                &::before {
                    display: none;
                }
            }
        }
        .client-thumbnails {
            margin: 0;

            .next-arrow {
                right: 0;
            }
            .prev-arrow {
                left: 0;
            }
            .next-arrow, .prev-arrow {
                background: rgba(0,0,0,.55);
                color: #ffffff;
                border: none;
            }
        }
    }
    .testimonials-slides-box {
        &::before, &::after {
            display: none;
        }
    }
    .testimonials-slides {
        max-width: 100%;
        margin: 0;

        &::before, &::after {
            display: none;
        }
        .client-info {
            .image {
                padding-right: 15px;
            }
            .title {
                h3 {
                    font-size: 17px;
                }
            }
        }
        p {
            font-size: 14px;
        }
    }
    .single-feedback-item {
        padding: 40px 20px 90px;

        &::before {
            font-size: 30px;
            left: 15px;
        }
    }

    .ready-to-talk {
        padding: {
            top: 50px;
            bottom: 145px;
        }

        h3 {
            font-size: 20px;
        }
        span {
            display: block;
            margin-top: 25px;
        }
    }

    .partner-section {
        h5 {
            font-size: 17px;
            line-height: 23px;
        }
        .partner-inner {
            padding: {
                left: 20px;
                right: 20px;
            }
        }
    }

    .blog-area {
        .widget-area {
            margin-top: 30px;
        }
    }
    .single-blog-post {
        .blog-post-content {
            h3 {
                font-size: 17px;
            }
        }
        .blog-image {
            a {
                img {
                    width: 100%;
                }
            }
        }
    }

    .page-title-area {
        background-position: center center;
        padding: {
            top: 150px;
            bottom: 65px;
        }
        .shape8 {
            top: auto;
            right: auto;
            left: 15px;
            display: block;
            bottom: 20px;
        }
        .shape7 {
            left: 15%;
            top: 29%;
            display: block;
        }
    }

    .about-image {
        margin-bottom: 45px;
    }
    .about-inner-area {
        margin-top: 0px;

        .about-text {
            margin-top: 30px;

            h3 {
                font-size: 17px;
            }
        }
    }

    .project-details-desc {
        h3 {
            font-size: 17px;
            margin-top: 0;
        }
        .project-details-information {
            .single-info-box {
                float: left;
                width: 50%;
                margin-top: 20px;

                h4 {
                    font-size: 17px;
                }
            }
        }
    }

    .faq-accordion {
        padding: 0;
        box-shadow: unset;

        .accordion {
            .accordion-item {
                .accordion-title {
                    font-size: 15px;
                }
            }
        }
    }
    .faq-contact {
        padding: 30px 20px;

        h3 {
            font-size: 20px;
        }
    }

    .pagination-area {
        margin-top: 15px;
    }

    .blog-details-desc {
        .article-content {
            h1, h2, h3 {
                font-size: 20px;
                line-height: 1.4;
            }
            h4, h5 {
                font-size: 18px;
                line-height: 1.4;
            }
        }
        .article-footer {
            margin-top: 25px;

            .article-tags {
                a {
                    margin-top: 5px;
                }
            }
        }
    }
    blockquote, .blockquote {
        padding: 20px !important;

        p {
            font-size: 16px !important;
        }
    }
    .comments-area {
        .comments-title {
            font-size: 17px;
        }
        .comment-author {
            font-size: 14px;
        }
        .comment-content {
            p {
                font-size: 13px;
            }
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 17px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
        }
    }
    .prev-link-wrapper {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;

        .prev-title {
            font-size: 14px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .next-link-wrapper {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;

        .next-title {
            font-size: 14px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }

    .contact-info-area {
        padding-bottom: 20px;
    }
    .contact-info-box {
        padding: 30px 20px;
        margin-bottom: 30px;

        h3 {
            font-size: 17px;
        }
    }
    .contact-form {
        margin-top: 45px;
    }

    .coming-soon-area {
        height: 100%;
        padding: {
            top: 170px;
            bottom: 170px;
        }
        .social-list {
            bottom: 20px;
        }
    }
    .coming-soon-content {
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 14px;
        }
        form {
            .submit-btn {
                position: relative;
                right: 0;
                top: 0;
                height: 45px;
                border-radius: 40px;
                width: 130px;
                font-size: 14px;
                margin-top: 18px;
                box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.3);
            }
        }
        #timer {
            div {
                width: 100px;
                height: 100px;
                margin: 0 10px;
                padding-top: 18px;
                font-size: 30px;
                margin-bottom: 25px;

                span {
                    font-size: 13px;
                }
            }
        }
    }

    .error-content {
        margin-top: 0;

        .notfound-404 {
            height: 95px;
            margin-bottom: 25px;

            h1 {
                font-size: 75px;
            }
        }
        h3 {
            font-size: 19px;
            margin-bottom: 15px;
        }
        p {
            font-size: 13px;
        }
    }

    .woocommerce-topbar {
        text-align: center;

        .woocommerce-result-count {
            margin-bottom: 10px;
        }
        .woocommerce-topbar-ordering {
            .form-select {
                height: 40px;
                font-size: 12px;
                text-align: center;
            }
        }
    }
    .single-products {
        .products-content {
            h3 {
                font-size: 17px;
                margin-bottom: 10px;
            }
            .add-to-cart-btn {
                font-size: 13px;
            }
        }
    }
    #productsModalCenter {
        z-index: 999999;
        
        .modal-content {
            button {
                &.close {
                    z-index: 2;
                }
            }
            .products-content {
                text-align: center;
                padding: 25px 10px;
    
                h3 {
                    font-size: 17px;
                }
            }
        }
    }

    .products-details {
        margin-top: 20px;

        h3 {
            font-size: 17px;
            margin-bottom: 10px;
        }
        form {
            button {
                padding: 0 13px;
            }
            .quantity {
                display: block;
                padding-right: 0;
                margin-bottom: 15px;

                input {
                    height: 45px;
                }
            }
            .add-to-wishlist-btn {
                width: 40px;
            }
        }
    }
    .products-details-tabs {
        .react-tabs__tab-list {
            li {
                display: block;
                margin-bottom: 2px;
            }
        }
    }

    .cart-table {
        table {
            thead {
                tr {
                    th {
                        padding: 15px 15px;
                        white-space: nowrap;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        white-space: nowrap;
                        padding: {
                            left: 10px;
                            right: 10px;
                        }
                        &.product-subtotal {
                            .remove {
                                float: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .cart-buttons {
        text-align: center;

        .continue-shopping-box {
            margin-bottom: 12px;
        }
        .text-right {
            text-align: center !important;
        }
    }
    .cart-totals {
        padding: 20px;
        margin: 35px auto 0;

        h3 {
            font-size: 17px;
        }
    }

    .checkout-area {
        .title {
            font-size: 17px;
        }
    }
    .billing-details {
        padding: 20px;
    }
    .order-details {
        padding: 20px;
        margin-top: 30px;
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 17px;
            }
        }
        .widget_startp_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 14px;
                    }
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 13px;
                }
            }
        }
        .widget_archive {
            ul {
                li {
                    font-size: 13px;
                }
            }
        }
        .widget_meta {
            ul {
                li {
                    font-size: 13px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 13px !important;
            }
        }
    }

    .services-details-desc {
        h3 {
            font-size: 18px;
        }
    }
    .services-details-image {
        order: 1;
        margin-bottom: 30px;
    }
    .services-details {
        order: 2;
    }
    .services-details-area {
        .separate {
            margin-top: 30px;
        }
    }

    .features-details-desc {
        h3 {
            font-size: 18px;
        }
    }
    .features-details-image {
        order: 1;
        margin-bottom: 30px;
    }
    .features-details {
        order: 2;
    }
    .features-details-area {
        .separate {
            margin-top: 30px;
        }
    }

    .repair-main-banner {
        padding: {
            top: 150px;
            bottom: 85px;
        }
    }
    .repair-banner-content {
        text-align: center;

        h1 {
            line-height: 1.4;
            margin-bottom: 17px;
            font-size: 25px;
        }
    }
    .repair-banner-image {
        margin-top: 60px;

        img {
            &:nth-child(2) {
                top: 0;
                right: 0;
            }
            &:nth-child(3) {
                top: -35px;
            }
            &:nth-child(4) {
                display: none;
            }
        }
    }

    .repair-about-content {
        margin-bottom: 40px;

        .sub-title {
            font-size: 14px;
        }
        h2 {
            font-size: 20px;
        }
        ul {
            li {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 15px;
            }
        }
    }
    .repair-about-image {
        text-align: center;
        padding: {
            top: 35px;
            bottom: 35px;
        }
        img {
            &:nth-child(2) {
                width: 250px;
                position: relative;
            }
            &:nth-child(4), &:nth-child(5) {
                display: none;
            }
        }
    }

    .repair-services-area {
        padding-bottom: 20px;
    }
    .single-repair-services {
        padding: 30px 20px;

        h3 {
            font-size: 17px;
        }
        .icon {
            width: 65px;
            height: 65px;
            line-height: 65px;

            i {
                &::before {
                    font-size: 25px;
                }
            }
        }
    }

    .repair-cta-area {
        padding: {
            top: 50px;
            bottom: 50px;
        }
    }
    .cta-repair-content {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 21px;
            margin-bottom: 17px;
        }
        p {
            font-size: 13px;
        }
    }
    .cta-repair-img {
        text-align: center;
    }

    .single-repair-feedback {
        border-radius: 5px;
        margin-bottom: 50px;
        padding: {
            left: 20px;
            right: 20px;
            top: 20px;
            bottom: 20px;
        }
        &::after {
            font-size: 30px;
            left: 10px;
        }
        .client-img {
            margin-bottom: 29px;
            padding: {
                left: 85px;
                top: 13px;
            }
            img {
                width: 70px;
                height: 70px;
            }
            span {
                margin-top: 5px;
            }
            h3 {
                font-size: 17px;
            }
        }
        p {
            font-size: 14px;
        }
    }
    .single-repair-box {
        padding: 20px;

        .icon {
            width: 75px;
            height: 75px;
            line-height: 75px;

            i {
                &::before {
                    font-size: 25px;
                }
            }
            h3 {
                font-size: 17px;
            }
        }
        .back-icon {
            i {
                &::before {
                    font-size: 170px;
                }
            }
        }
    }

    .iot-main-banner {
        padding: {
            top: 150px;
            bottom: 80px;
        }
    }
    .iot-banner-content {
        text-align: center;
        max-width: 100%;

        span {
            font-size: 14px;
        }
        h2 {
            font-size: 25px;
            margin-bottom: 17px;
            line-height: 1.4;
        }
    }
    .iot-banner-image {
        position: relative;
        right: 0;
        top: 0;
        transform: unset;
        margin-top: 50px;

        img {
            &:first-child {
                max-width: 100%;
            }
            &:last-child {
                display: none;
            }
        }
    }
    .animate-border {
        span {
            top: 78%;
            left: 41%;
        }
    }

    .single-iot-services {
        padding: 30px 20px;

        .icon {
            width: 65px;
            height: 65px;
            line-height: 65px;

            i {
                &::before {
                    font-size: 25px;
                }
            }
        }
        h3 {
            font-size: 17px;
        }
    }

    .iot-cta-area {
        padding: {
            top: 50px;
            bottom: 50px;
        }
    }
    .cta-iot-content {
        text-align: center;
        margin-top: 35px;

        h3 {
            font-size: 20px;
            line-height: 1.5;
        }
    }

    .iot-features-content {
        order: 1;
        margin-bottom: 30px;

        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            line-height: 1.5;
        }
    }
    .iot-features-image {
        order: 2;
    }
    .single-iot-box {
        padding: 20px;

        .icon {
            width: 85px;
            height: 85px;
            line-height: 85px;

            img {
                width: 40px;
            }
            i {
                &::before {
                    font-size: 25px;
                }
            }
        }
        h3 {
            font-size: 17px;
            margin: {
                top: 20px;
                bottom: 15px;
            }
        }
    }
    
    .ml-main-banner {
        background-position: center center;
        padding: {
            top: 130px;
            bottom: 140px;
        };
    }
    .ml-main-banner {
        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .ml-banner-content {
        text-align: center;

        h1 {
            font-size: 25px;
            margin-bottom: 14px;
        }
        p {
            max-width: 100%;
            font-size: 13px;
        }
    }
    .ml-banner-single-image {
        margin-top: 30px;
    }
    .ml-main-section {
        background-position: center center;
        padding: {
            top: 130px;
            bottom: 140px;
        };
        .ml-banner-content {    
            h1 {
                font-size: 25px;
            }
        }
    }
    .ml-banner-image {
        height: auto;
        top: 0;
        margin-top: 30px;
        text-align: center;

        img {
            display: none;
            position: relative;

            &:nth-child(12) {
                display: inline-block;
            }
        }
    }

    .ml-partner-area {
        &.mt-minus-top {
            margin-top: -125px;
        }
    }

    .solutions-area {
        padding-bottom: 20px;
    }
    .single-solutions-box {
        h3 {
            font-size: 17px;
        }
        .learn-more-btn {
            font-size: 13px;

            svg {
                width: 15px;
            }
        }
    }

    .section-title {
        .sub-title {
            margin-bottom: 15px;
            padding: 4px 15px 3px;
            font-size: 12px;
        }
    }

    .ml-about-content {
        max-width: 100%;
        text-align: center;
        margin-top: 30px;

        h2 {
            font-size: 20px;
        }
        .bar {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }

    .ml-services-area {
        padding-bottom: 20px;
    }
    .single-ml-services-box {
        padding: 25px 20px;

        h3 {
            font-size: 17px;
        }
    }

    .ml-projects-area {
        .section-title {
            text-align: center;
            max-width: 100%;

            h2 {
                max-width: 100%;
            }
            .bar {
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
    }

    .pricing-tab {
        .tabs {
            margin-bottom: 30px;

            li {
                padding: 10px 25px 9.5px;
                font-size: 14px;
            }
        }
    }
    .pricing-box {
        padding: 25px;

        .pricing-header {
            h3 {
                font-size: 18px;
            }
        }
        .price {
            font-size: 25px;
            margin: {
                bottom: 15px;
                top: 15px;
            };
        }
        .pricing-features {
            padding: {
                left: 0;
                right: 0;
            };
            li {
                padding-left: 24px;
                font-size: 13px;

                svg {
                    width: 17px;
                }
            }
        }
    }
    .ml-feedback-slides {
        max-width: 100%;
        margin: -40px 0 0;
    }
    .single-ml-feedback-item {
        padding: 20px;
        text-align: center;

        .client-info {
            position: relative;
            left: 0;
            top: 0;
            transform: unset;
            text-align: center;
            margin-bottom: 15px;

            h3 {
                font-size: 17px;
            }
        }
        p {
            font-size: 13px;
        }
        &::after {
            margin: {
                left: 20px;
                right: 20px;
            };
        }
    }

    .single-blog-post-box {
        .entry-post-content {
            h3 {
                font-size: 17px;
            }
            .learn-more-btn {
                font-size: 13px;
            }
        }
    }

    .funfacts-area {
        .section-title {
            h2 {
                line-height: 30px;
            }
        }
    }

    .free-trial-area {
        padding-bottom: 50px;

        &::before {
            display: none;
        }
    }
    .free-trial-content {
        max-width: 100%;
        text-align: center;
        margin-top: 30px;

        h2 {
            font-size: 20px;
        }
        form {
            margin-top: 20px;

            .input-newsletter {
                width: 100%;
            }
            button {
                position: relative;
                font-size: 15px;
                border-radius: 30px;
                margin-top: 15px;
            }
        }
    }

    .agency-main-banner {
        padding-top: 120px;

        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .agency-banner-content {
        max-width: 100%;
        text-align: center;
        margin: {
            bottom: 30px;
            left: 0;
        };
        .sub-title {
            font-size: 13px;
        }
        h1 {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
            max-width: 100%;
        }
    }

    .solutions-box {
        margin-bottom: 40px;
        text-align: center;

        .icon {
            margin-bottom: 20px;
            padding-left: 15px;

            i {
                font-size: 40px;
            }
            img {
                width: 80px;
                left: 40%;
            }
        }
        h3 {
            font-size: 17px;
        }
        .learn-more-btn {
            font-size: 13px;
        }
    }

    .agency-about-area {
        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .agency-about-img {
        padding-right: 0;
        text-align: center;
        margin-bottom: 30px;
    }
    .agency-about-content {
        max-width: 100%;
        text-align: center;
        margin-top: 30px;

        h2 {
            font-size: 20px;
        }
        .bar {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .agency-services-box {
        .content {
            padding: 20px;
            margin: {
                left: 10px;
                right: 10px;
                top: -20px;
            };
            h3 {
                font-size: 17px;
            }
        }
        &:hover {
            .content {
                h3 {
                    transform: translateY(0);
                }
                .read-more-btn {
                    display: none;
                }
            }
        }
    }

    .user-shape1, .user-shape2, .user-shape3, .user-shape4, .user-shape5, .user-shape6 {
        display: none;
    }
    .single-testimonials-item {
        padding: 64px 20px 20px;
        text-align: center;

        p {
            font-size: 13px;
        }
        &::before {
            top: 8px;
            font-size: 35px;
        }
        .client-info {
            margin-top: 20px;
            max-width: 200px;

            .rating {
                svg {
                    width: 14px;
                }
            }
            span {
                font-size: 12px;
            }
            h3 {
                margin-top: 5px;
                font-size: 15px;
            }
        }
    }

    .single-blog-post-item {
        .post-content {
            padding: 25px;
            margin: {
                left: 10px;
                right: 10px;
                top: -20px;
            };
            h3 {
                font-size: 17px;
                line-height: 25px;
            }
        }
    }

    .agency-cta-content {
        h2 {
            margin-bottom: 25px;
            font-size: 20px;
        }
    }

    .agency-portfolio-main-banner {
        height: 100%;
        padding: {
            top: 150px;
            bottom: 120px;
        };
    }
    .portfolio-banner-content {
        text-align: center;
        max-width: 100%;
        margin-top: 0;
        
        .sub-title {
            font-size: 14px;
        }
        h1 {
            font-size: 25px;
            margin-bottom: 12px;
        }
        p {
            max-width: 100%;
            font-size: 13px;
        }
    }

    .section-title {
        &.text-left {
            text-align: center !important;

            p {
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
    }

    .shorting-menu {
        text-align: center;
        margin-bottom: 25px;

        button {
            margin: {
                left: 6px;
                right: 6px;
                bottom: 5px;
            };
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .single-latest-projects {
        .content {
            left: 5%;

            h3 {
                font-size: 17px;
            }
            span {
                font-size: 12px;
            }
        }
    }
    .agency-portfolio-about-area {
        padding-bottom: 20px;
    }
    .single-text-box {
        text-align: center;
        h3 {
            font-size: 17px;
        }
    }

    .agency-portfolio-feedback-slides {
        max-width: 100%;
    }
    .agency-portfolio-feedback-item {
        p {
            font-size: 14px;
        }
    }

    .single-blog-item {
        .post-content {
            h3 {
                line-height: 28px;
                font-size: 17px;
            }
        }
    }

    .creative-inner-area {
        .single-counter {
            padding: 30px;
            text-align: center;

            h3 {
                font-size: 26px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    
    .footer-area {
        padding-top: 50px;
    }
    .single-footer-widget {
        padding: 0 !important;
        margin-bottom: 30px;

        h3 {
            font-size: 17px;
            margin-bottom: 20px;
        }
    }
    .copyright-area {
        margin-top: 20px;
        padding: {
            top: 20px;
            bottom: 20px;
        }
    }
    .navbar-color-white {
        &#header.headroom {
            border-color: #4a4545;
        }
    }

    .bigdata-analytics-banner {
        padding: {
            top: 170px;
            bottom: 20px;
        };
    }
    .bigdata-analytics-content {
        text-align: center;
        max-width: 100%;

        h1 {
            font-size: 27px;
            margin-bottom: 20px;
        }
        p {
            max-width: 100%;
            font-size: 14px;
        }
        .btn {
            margin-top: 10px;
        }
    }
    .banner-boxes-area {
        margin-top: 100px;
    }
    .single-banner-boxes {
        padding: 20px 20px 20px 85px;

        h3 {
            margin-bottom: 10px;
            font-size: 17px;
        }
        .icon {
            width: 50px;
            height: 50px;
            left: 15px;
            top: 20px;
            line-height: 50px;
        }
    }

    .single-what-we-do-box {
        padding-left: 70px;

        .icon {
            width: 50px;
            height: 50px;

            i::before {
                font-size: 30px;
            }
        }
        h3 {
            margin-bottom: 9px;
            font-size: 17px;
        }
    }

    .discover-image {
        margin-right: 0;
        padding: {
            bottom: 0;
            right: 0;
        };
        img {
            width: 100%;

            &:nth-child(2) {
                position: relative;
                margin-top: 20px;
            }
        }
    }
    .discover-content {
        padding-left: 0;
        text-align: center;
        margin-top: 30px;

        h2 {
            font-size: 20px;
        }
    }
    .analytics-shape1 {
        display: none;
    }

    .single-services-box-item {
        padding: 70px 20px 50px;

        .icon {
            width: 65px;
            height: 65px;
            
            img {
                max-width: 40px;
            }
        }
        h3 {
            font-size: 17px;
        }
    }

    .repair-team-area {
        .container {
            max-width: 100%;
        }
    }

    .single-funfact {
        padding: 20px;
        margin-bottom: 30px;

        h3 {
            font-size: 30px;
            line-height: 1;
        }
    }

    .single-feedback-box {
        margin: {
            left: 15px;
            top: 15px;
        };
        .client-info {
            h3 {
                font-size: 16px;
            }
        }
        .feedback-desc {
            padding: {
                left: 25px;
                right: 25px;
                bottom: 25px;
            };
        }
        &::before {
            top: -15px;
            left: -15px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 25px;
        }
    }

    .industries-serve-area {
        padding-bottom: 20px;
    }
    .single-industries-serve-box {
        text-align: center;
        padding: 15px 8px;
        font-size: 14px;

        .icon {
            position: relative;
            left: 0;
            top: 0;
            transform: translateY(0);
            font-size: 30px;
            line-height: 1;
            margin-bottom: 8px;
        }
    }

    .newsletter-area {
        padding-bottom: 50px;
        
        &::before {
            display: none;
        }
        .container-fluid {
            max-width: 100%;
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .newsletter-content {
        padding: 20px;
        max-width: 100%;
        text-align: center;

        &::before {
            display: block;
        }
        h2 {
            font-size: 20px;
        }
        .newsletter-form {
            padding-right: 0;

            .input-newsletter {
                height: 45px;
                padding-left: 15px;
                font-size: 12px;
            }
            button {
                position: relative;
                height: auto;
                padding: 14px 30px 12px;
                margin-top: 15px;
                line-height: initial;
                display: block;
                width: 100%;
                font-size: 13px;
            }
        }
        p {
            max-width: 100%;
        }
    }
    #header {
        &.headroom {
            background-color: #ffffff;
        }
    }
    .startp-nav {
        nav {
            .navbar-collapse {
                max-height: 70vh;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 8px;
                } 
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                } 
                &::-webkit-scrollbar-thumb {
                    background: rgb(165, 162, 162); 
                    border-radius: 30px;
                } 
                &::-webkit-scrollbar-thumb:hover {
                    background: #555; 
                }
            }
            .navbar-nav {
                .nav-item {
                    padding: 5px 0;
                    > a {
                        margin: 0 0 5px;
                        svg {
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        padding: 10px 0;
                        position: relative;
                        visibility: visible;
                        opacity: 1;
                        top: 0;
                        width: 95%;
                        border: 1px solid #f3f3f3;
                        margin-bottom: 10px;

                        li {
                            a {
                                padding: 6px 15px;
                            }
                            .dropdown-menu {
                                padding: 10px 0;
                                position: relative;
                                visibility: visible !important;
                                opacity: 1 !important;
                                left: 15px;
                                top: 0;
                                width: 90%;
                                border: 1px solid #f3f3f3;
                            }
                        }
                    }
                }
            }
            .others-option {
                position: absolute;
                right: 45px;
                top: 9px;
                .btn.btn-light {
                    border: 1px dashed #cdf1d8;
                    display: none;
                }
                .btn.btn-light, .btn {
                    padding: 6px 12px;
                    margin-right: 5px;
                    font-size: 10px;
                }
                .cart-wrapper-btn {
                    margin-right: 25px;
                }
            }
        }
    }
 
    .pt-80 {
        padding-top: 50px;
    }
    .pb-50 {
        padding-bottom: 20px;
    }

    .go-top {
        width: 35px;
        height: 35px;
        line-height: 30px;
        svg {
            width: 18px;
        }
    }
    .pb-80 {
        padding-bottom: 50px;
    }
    .navbar-style-three {
        .startp-nav {
            padding-left: 0;
            padding-right: 0;
        }
    }
    #header.headroom.navbar-style-four {
        background-color: #0e314c;
        .navbar-toggler {
            .icon-bar {
                background: #ffffff;
            }
        }
    }
    
    .agency-portfolio-home-slides {
        .swiper-button-next, .swiper-button-prev {
            width: 35px;
            height: 35px;
            top: auto;
            bottom: 30px;
    
            &:after {
                width: 35px;
                height: 35px;
                line-height: 35px;
                font-size: 20px;
            }
        }
    }
}

/* Min width 576px to Max width 767px */
@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
    .startp-nav {
        nav {
            .others-option {
                .btn.btn-light {
                    display: inline-block;
                }
            }
        }
    }
    .repair-about-content {
        ul {
            li {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .single-repair-feedback {
        &::after {
            font-size: 65px;
        }
    }
    .single-feedback-item {
        &::before {
            font-size: 65px;
        }
    }

    .woocommerce-topbar {
        text-align: left;

        .woocommerce-result-count {
            margin-bottom: 0;
        }
    }
    #productsModalCenter {
        .modal-content {
            .products-content {
                text-align: left;
            }
        }
    }

    .products-details {
        form {
            .quantity {
                display: inline-block;
                padding-right: 10px;
                margin-bottom: 0;
            }
        }
    }

    .cart-buttons {
        text-align: left;

        .text-right {
            text-align: right !important;
        }
        .continue-shopping-box {
            margin-bottom: 0;
        }
    }

    .repair-why-choose-us {
        &::before {
            height: 45%;
        }
    }

    .iot-why-choose-us {
        &::before {
            height: 45%;
        }
    }
    
    .ml-main-banner {
        .container-fluid {
            max-width: 540px;
        }
    }

    .about-area {
        .container-fluid {
            max-width: 540px;
        }
    }

    .free-trial-area {
        .container-fluid {
            max-width: 540px;
        }
    }
    .free-trial-content {
        form {
            button {
                position: absolute;
                border-radius: 0 30px 30px 0;
                margin-top: 0;
            }
        }
    }

    .agency-main-banner {
        .container-fluid {
            max-width: 540px;
        }
    }

    .agency-about-area {
        .container-fluid {
            max-width: 540px;
        }
    }

    .repair-team-area {
        .container {
            max-width: 540px;
        }
    }

    .single-banner-boxes {
        padding: 20px;

        .icon {
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: 20px;
        }
    }

}

/* Min width 768px to Max width 991px */
@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    .main-banner {
        height: 100%;
        padding: {
            top: 185px;
            bottom: 165px;
        }
        .banner-image {
            text-align: center;
            margin-top: 45px;
            
            img {
                position: relative;
                display: none;

                &:last-child {
                    display: block;
                }
            }
        }
        .hero-content {
            h1 {
                font-size: 30px;
                margin-bottom: 15px;
            }
        }
        .banner-form {
            padding: 50px;
            margin: 45px 0 !important;
        }
    }
    .hosting-main-banner {
        height: 100%;;
        padding: {
            top: 185px;
            bottom: 165px;
        }
        .hosting-banner-content {
            h1 {
                font-size: 30px;
            }
            ul {
                li {
                    font-size: 13px;
                }
            }
            p {
                font-size: 16px;
            }
        }
        .hosting-banner-image {
            margin-top: 50px;

            img {
                position: relative;
                display: none;

                &:nth-child(10) {
                    display: block;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                }
            }
        }
    }
    .shape1, .shape2, .shape3, .shape4, .shape5, .shape7 {
        display: none;
    }
    .section-title {
        h2 {
            font-size: 25px;
        }
    }

    .boxes-area {
        padding-bottom: 50px;
        margin-top: -65px;
    }
    .single-box {
        margin-bottom: 30px;
    }

    .why-choose-us-image {
        margin-bottom: 65px;

        img {
            display: none;

            &:nth-child(3) {
                display: block;
            }
            &:last-child {
                display: block;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
    }

    .services-right-image {
        text-align: center;
        margin-top: 45px;
        
        img {
            display: none;
            position: relative;

            &:last-child {
                display: block;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &.single-right-image {
            img {
                &.bg-image {
                    display: none;
                }
            }
        }
    }
    .services-left-image {
        text-align: center;
        margin-bottom: 45px;

        img {
            display: none;
            position: relative;

            &:last-child {
                display: block;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &.single-left-image {
            img {
                &.bg-image {
                    display: none;
                }
            }
        }
    }
    
    .single-features {
        padding-left: 20px;

        .icon {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 25px;
        }
    }

    .about-image {
        margin-bottom: 45px;
    }
    .about-inner-area {
        margin-top: 30px;

        .about-text {
            margin-top: 30px;
        }
    }

    .single-feedback-item {
        padding: 40px 80px 90px;
    }

    .cta-content {
        h3 {
            line-height: 1.5;
        }
    }

    .pricing-area {
        padding-bottom: 50px;
    }
    .single-pricing-table {
        margin-bottom: 30px;
    }

    .page-title-area {
        padding: {
            top: 130px;
            bottom: 50px;
        }
        .shape8 {
            top: auto;
            right: auto;
            left: 20px;
            bottom: 20px;
        }
    }

    .project-details-desc {
        .project-details-information {
            .single-info-box {
                width: 33.3333%;
            }
        }
    }

    .contact-info-area {
        padding-bottom: 50px;
    }
    .contact-info-box {
        margin-bottom: 30px;
    }
    .contact-form {
        margin-top: 45px;
    }

    .coming-soon-content {
        h1 {
            font-size: 35px;
        }
        p {
            font-size: 15px;
        }
        #timer {
            div {
                padding-top: 20px;
                font-size: 30px;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .error-content {
        .notfound-404 {
            height: 205px;

            h1 {
                font-size: 170px;
            }
        }
    }

    .products-details {
        margin-top: 25px;
    }

    .services-details {
        order: 2;
    }
    .services-details-image {
        order: 1;
        margin-bottom: 40px;
    }
    .services-details-area {
        .separate {
            margin-top: 40px;
        }
    }

    .features-details {
        order: 2;
    }
    .features-details-image {
        order: 1;
        margin-bottom: 40px;
    }
    .features-details-area {
        .separate {
            margin-top: 40px;
        }
    }

    .repair-main-banner {
        padding-top: 155px;
    }
    .repair-banner-content {
        text-align: center;
        margin-bottom: 115px;

        h1 {
            margin-bottom: 18px;
            font-size: 30px;
        }
    }
    .repair-banner-image {
        img {
            &:nth-child(4) {
                left: 0;
                top: -40px;
                margin: 0 auto;
            }
        }
    }

    .repair-about-content {
        margin-bottom: 45px;
    }
    .repair-about-image {
        text-align: center;
        padding: 35px 0;

        img {
            &:nth-child(2) {
                position: relative;
            }
            &:nth-child(4), &:nth-child(5) {
                display: none;
            }
        }
    }

    .repair-cta-area {
        padding-top: 80px;
    }
    .cta-repair-content {
        margin-bottom: 30px;
    }

    .repair-why-choose-us {
        &::before {
            height: 40%;
        }
    }

    .iot-main-banner {
        padding: {
            top: 190px;
            bottom: 100px;
        }
    }
    .iot-banner-content {
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 30px;
        }
    }
    .iot-banner-image {
        position: relative;
        right: 0;
        top: 0;
        transform: unset;
        margin-top: 80px;
            
        img {
            &:first-child {
                max-width: 100%;
            }
        }
    }
    .animate-border {
        span {
            top: 70%;
            left: 42%;
        }
    }

    .iot-cta-area {
        padding: {
            top: 80px;
            bottom: 80px;
        }
    }
    .cta-iot-content {
        text-align: center;
        margin-top: 40px;
    }

    .iot-features-content {
        order: 1;
    }
    .iot-features-image {
        order: 2;
        margin-top: 40px;
    }

    .iot-why-choose-us {
        &::before {
            height: 40%;
        }
    }

    .blog-area {
        .widget-area {
            margin-top: 30px;
        }
    }

    .widget-area {
        margin-top: 40px;
    }

    .single-footer-widget {
        margin-bottom: 30px;
    }
    .copyright-area {
        margin-top: 50px;
    }
    
    .ml-main-banner {
        background-position: center center;
        padding-top: 150px;

        .container-fluid {
            max-width: 720px;
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .ml-banner-single-image {
        margin-top: 30px;
    }
    .ml-banner-content {
        text-align: center;

        h1 {
            font-size: 30px;
        }
        p {
            max-width: 100%;
            font-size: 14px;
        }
    }
    .ml-main-section {
        background-position: center center;
        padding: {
            top: 150px;
            bottom: 140px;
        };
        .ml-banner-content {    
            h1 {
                font-size: 30px;
            }
        }
    }
    .ml-banner-image {
        height: auto;
        top: 0;
        margin-top: 30px;
        text-align: center;

        img {
            display: none;
            position: relative;

            &:nth-child(12) {
                display: inline-block;
            }
        }
    }

    .ml-partner-area {
        &.mt-minus-top {
            margin-top: -120px;
        }
    }

    .about-area {
        .container-fluid {
            max-width: 720px;
        }
    }
    .ml-about-content {
        text-align: center;
        max-width: 620px;
        margin: {
            left: auto;
            right: auto;
        };
        h2 {
            font-size: 25px;
        }
        .bar {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
  
    .free-trial-area {
        padding-bottom: 80px;

        .container-fluid {
            max-width: 720px;
        }
        &::before {
            display: none;
        }
    }
    .free-trial-content {
        text-align: center;
        margin: {
            left: auto;
            right: auto;
            top: 30px;
        };
        h2 {
            font-size: 25px;
        }
        form {
            margin-top: 20px;
        }
    }

    .agency-main-banner {
        padding-top: 120px;

        .container-fluid {
            max-width: 720px;
            padding: {
                left: 15px;
                right: 15px;
            };
        }
        .shape8 {
            display: none;
        }
    }
    .agency-banner-content {
        max-width: 100%;
        text-align: center;
        margin: {
            bottom: 30px;
            left: 0;
        };
        .sub-title {
            font-size: 14px;
        }
        h1 {
            font-size: 35px;
            line-height: 32px;
            margin-bottom: 12px;
        }
        p {
            font-size: 15px;
            max-width: 80%;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }

    .agency-about-area {
        .container-fluid {
            max-width: 720px;
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .agency-about-img {
        padding-right: 0;
        text-align: center;
        margin-bottom: 30px;
    }
    .agency-about-content {
        max-width: 540px;
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        };
        h2 {
            font-size: 25px;
        }
        .bar {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .shape9 {
        display: none;
    }
    .user-shape1, .user-shape2, .user-shape3, .user-shape4, .user-shape5, .user-shape6 {
        display: none;
    }

    .agency-portfolio-main-banner {
        height: 100%;
        padding: {
            top: 200px;
            bottom: 150px;
        };
    }
    .portfolio-banner-content {
        text-align: center;
        margin-top: 0;

        .sub-title {
            font-size: 15px;
        }
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 14px;
        }
    }

    .our-latest-projects {
        .section-title {
            margin-bottom: 40px;
        }
    }
    .section-title {
        &.text-left {
            text-align: center !important;

            p {
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
    }

    .shorting-menu {
        text-align: center;
        margin-bottom: 30px;
    }

    .navbar-color-white {
        &#header.headroom {
            border-color: #4a4545;
        }
    }

    .bigdata-analytics-banner {
        padding: {
            top: 230px;
        };
    }
    .bigdata-analytics-content {
        max-width: 530px;

        h1 {
            font-size: 40px;
        }
    }
    .banner-boxes-area {
        margin-top: 200px;
    }

    .discover-image {
        margin-right: 0;
    }
    .discover-content {
        text-align: center;
        max-width: 540px;
        padding-left: 0;
        margin: {
            left: auto;
            right: auto;
            top: 35px;
        };
    }

    .single-services-box-item {
        padding: 80px 30px 60px;
    }

    .repair-team-area {
        .container {
            max-width: 720px;
        }
    }

    .single-funfact {
        padding: 20px;
    }

    .newsletter-area {
        padding-bottom: 80px;
        
        &::before {
            display: none;
        }
        .container-fluid {
            max-width: 720px;
        }
    }
    .newsletter-content {
        padding: 40px;
        max-width: 100%;
        text-align: center;

        &::before {
            display: block;
        }
        .newsletter-form {
            max-width: 540px;
            margin: {
                left: auto;
                right: auto;
            };
        }
        p {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    #header {
        &.headroom {
            padding: 10px 0;
            &.is-sticky {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
    
    .startp-nav {
        nav {
            .navbar-nav {
                .nav-item {
                    a {
                        font-size: 14px;
                        margin-left: 6px;
                        margin-right: 6px;
                    }
                    .dropdown-menu {
                        width: 200px;

                        li {
                            a {
                                padding: 6px 15px;
                                font-size: 13.5px;
                            }
                        }
                    }
                }
            }
            .others-option {
                .btn.btn-light {
                    border: 1px dashed #cdf1d8;
                    display: none;
                }
                .btn.btn-light, .btn {
                    padding: 6px 12px;
                    margin-right: 5px;
                    font-size: 10px;
                }
                .cart-wrapper-btn {
                    margin-right: 25px;
                }
            }
        }
    }
    .iot-banner-content {
        max-width: 100%;
    }

    .testimonials-slides-box {
        &::before, &::after {
            display: none;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {

    .main-banner {
        .hero-content {
            h1 {
                font-size: 35px;
            }
        }
        .banner-image {
            img {
                &:nth-child(1) {
                    top: -140px;
                    left: -120px;
                }
                &:nth-child(2) {
                    right: 160px;
                    top: -286px;
                }
                &:nth-child(3) {
                    left: -30px;
                    top: -15px;
                }
                &:nth-child(4) {
                    right: 15%;
                    top: 105px;
                }
                &:nth-child(5) {
                    left: 15%;
                    top: -120px;
                }
                &:nth-child(6) {
                    right: 37%;
                    top: -160px;
                }
                &:nth-child(7) {
                    right: 20px;
                    top: -110px;
                }
                &:nth-child(8) {
                    right: 25px;
                    top: -140px;
                }
                &:nth-child(9) {
                    right: 28%;
                    top: -25px;
                }
                &:nth-child(10) {
                    left: 29%;
                    top: -35px;
                }
                &:nth-child(11) {
                    left: 26%;
                    top: -160px;
                }
                &:nth-child(12) {
                    left: 15px;
                    top: -110px;
                }
                &:nth-child(13) {
                    left: 50%;
                    top: -18px;
                }
                &:nth-child(14) {
                    right: 98px;
                    top: -20px;
                }
            }
        }
    }
    .hosting-main-banner {
        .hosting-banner-content {
            h1 {
                font-size: 35px;
            }
        }
        .hosting-banner-image {
            img {
                &:nth-child(1) {
                    top: -85px;
                }
                &:nth-child(2) {
                    top: 175px;
                    left: 30px;
                }
                &:nth-child(3) {
                    top: 170px;
                    right: 30px;
                }
                &:nth-child(4) {
                    left: 18%;
                    top: -23px;
                }
                &:nth-child(5) {
                    right: 0;
                    top: -115px;
                }
                &:nth-child(6) {
                    left: 2%;
                    top: -173px;
                }
                &:nth-child(7) {
                    top: -244px;
                    left: -73px;
                }
                &:nth-child(8) {
                    left: 11%;
                    top: -200px;
                }
                &:nth-child(9) {
                    top: 40px;
                    left: 0;
                }
            }
        }
    }
    .shape1 {
        display: none;
    }

    .startp-nav {
        nav {
            .navbar-nav {
                .nav-item {
                    a {
                        margin: {
                            left: 10px;
                            right: 10px;
                        }
                    }
                }
            }
        }
    }
    .navbar-style-three {
        .startp-nav {
            padding: {
                left: 0;
                right: 0;
            };
        }
    }

    .services-left-image {
        &.single-left-image {
            img {
                &:first-child {
                    top: -30px;
                }
            }
        }
    }
    .services-right-image {
        &.single-right-image {
            img {
                &:first-child {
                    top: -30px;
                }
            }
        }
    }

    .repair-main-banner {
        padding: {
            top: 185px;
            bottom: 110px;
        }
    }
    .repair-banner-image {
        img {
            &:nth-child(4) {
                display: none;
            }
            &:nth-child(3) {
                top: -35px;
            }
            &:nth-child(2) {
                top: -30px;
            }
        }
    }
    .repair-team-area {
        .single-team {
            .team-content {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    .iot-main-banner {
        padding: {
            top: 200px;
            bottom: 120px;
        }
    }
    .iot-banner-image {
        img {
            &:first-child {
                max-width: 470px;
            }
            &:last-child {
                top: -10px;
            }
        }
    }
    .iot-team-area {
        .single-team {
            .team-content {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    .ml-main-section {
        padding: {
            top: 130px;
            bottom: 140px;
        };
        .ml-banner-content {
            h1 {
                font-size: 40px;
            }
        }
        .row {
            align-items: center!important;
        }
    }
    .ml-banner-image {
        height: auto;
        top: 0;
        text-align: center;

        img {
            display: none;
            position: relative;

            &:nth-child(12) {
                display: inline-block;
            }
        }
    }

    .agency-banner-content {
        max-width: 465px;
        position: relative;
        top: 50px;

        h1 {
            font-size: 40px;
        }
    }

    .agency-about-img {
        padding-right: 30px;
    }
    .agency-about-content {
        max-width: 475px;
    }

    .single-banner-boxes {
        padding: {
            left: 83px;
            right: 15px;
        };
        .icon {
            width: 50px;
            height: 50px;
            line-height: 50px;
            left: 15px;
        }
    }

    .single-services-box-item {
        padding: 80px 25px 60px;
    }
    .newsletter-content {
        max-width: 465px;
    }

    .startp-nav {
        nav {
            .others-option {
                .btn.btn-light, .btn  {
                    padding: 8px 20px;
                    font-size: 13px;
                }
                .btn.btn-light {
                    padding: 6px 20px !important;
                }
            }
        }
    }

    .single-box {
        padding: 20px 20px;
        h3 {
            font-size: 17px;
        }
    }

    .testimonials-slides-box {
        &::before {
            left: 25px;
            top: -25px;
        }
        &::after {
            right: 25px;
            bottom: -25px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonials-slides-box {
        &::before {
            left: 100px;
            top: -30px;
        }
        &::after {
            right: 100px;
            bottom: -30px;
        }
    }
}

/* Min width 1550px */
@media only #{$media} and ($feature_min : $value_seven) {

    .iot-banner-image {
        right: 90px;
        top: 56%;
        transform: translateY(-50%);

        img {
            &:first-child {
                max-width: 90%;
            }
            &:last-child {
                left: -128px;
                top: 50px;
            }
        }
    }
    .animate-border span {
        top: 51%;
        left: 70%;
    }

    .navbar-style-three {
        .startp-nav {
            padding: {
                left: 100px;
                right: 100px;
            };
        }
    }

    .ml-main-banner {
        .container-fluid {
            padding: {
                left: 170px;
                right: 170px;
            };
        }
    }

}

/* Min width 1200px */
@media only screen and (min-width : 1350px) {
    .container {
        max-width: 1250px;
    }
}